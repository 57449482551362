import React from "react"
import { withStyles } from "@material-ui/core"
import { ContentContainer } from "../moleculs/content-container"
import { ServicesFeedbackItem } from "../moleculs/services-feedback-item"
import { ServicesContentGrid } from "../moleculs/services-content-grid"


function ServicesFeedbackBlock(props) {
  const { classes, title, feedbacks = [], isGrayBg = false } = props

  const containerClass = isGrayBg ? classes.feedbackBg : ''

  return (
    <ContentContainer customClass={containerClass}>
      <ServicesContentGrid
        title={title}
        data={feedbacks}
        ItemComponent={ServicesFeedbackItem}
      />
    </ContentContainer>
  )
}

const styles = theme => ({
  feedbackBg: {
    background: "linear-gradient(180deg, #ECF2F6 0%, #FFFFFF 100%)"
  }
})

// eslint-disable-next-line
ServicesFeedbackBlock = withStyles(styles)(ServicesFeedbackBlock)

export { ServicesFeedbackBlock }

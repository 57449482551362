import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "./title-with-description-block"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import Grid from "@material-ui/core/Grid"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"
import { useInView } from "react-intersection-observer"


function ServicesContentGrid(props) {
  const {
    classes,
    width,
    title,
    data = [],
    isCardContainer = false,
    ItemComponent
  } = props
  const spacing = isWidthDown('sm', width) ? 4:6
  const gridClass = isCardContainer ? {} : classes.gridContainer

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.parentOffset?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function setItemClass () {
    if (inView) return `${classes.itemBlock} ${classes.itemAnimation}`
    return classes.itemBlock
  }


  function renderItem (dataItem, key) {
    const itemAnimationClass = setItemClass()

    return (
      <Grid
        item
        key={key}
        md={4}
        sm={12}
        className={itemAnimationClass}
      >
        <ItemComponent {...dataItem}/>
      </Grid>
    )
  }

  function renderItemsContainer () {
    return (
      <div className={classes.itemsContainer} ref={ref}>
        <Grid
          container
          spacing={spacing}
          className={gridClass}
        >
          {data.map(renderItem)}
        </Grid>
      </div>
    )
  }

  return (
    <div ref={sectionRef}>
      <TitleWithDescriptionBlock
        title={title}
        shouldAnimate={shouldAnimate}
        left={true}
      />
      {renderItemsContainer()}
    </div>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  itemsContainer: {
    marginTop: 100,
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      marginTop: 50
    },
  },
  gridContainer: {
    [theme.breakpoints.down("sm")]: {
      gap: "80px 0"
    },
  },
  itemBlock: {
    opacity: 0,
    animationDelay: "0.35s",

    "&:nth-child(1)":{
      animationDelay: "0.35s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.7s",
    },
    "&:nth-child(3)":{
      animationDelay: "1.05s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  itemAnimation: {
    ...theme.animations.itemFadeInUp,

    "&:nth-child(1)":{
      animationDelay: "0.35s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.7s",
    },
    "&:nth-child(3)":{
      animationDelay: "1.05s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  visible: {
    opacity: 1,
  }
})

// eslint-disable-next-line
ServicesContentGrid = withWidth()(withStyles(styles)(ServicesContentGrid))

export { ServicesContentGrid }

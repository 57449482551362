import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout } from "../components/organism/layout"
import Seo from "../components/seo"
import { WhatWeDoBlock } from "../components/moleculs/what-we-do-block"
import { OurDifferents } from "../components/moleculs/our-differents"
import { Advantages } from "../components/organism/advantages"
import { OneFeedbackBlock } from "../components/moleculs/one-feedback-block"
import { OurToolsSection } from "../components/organism/our-tools-section"
import { Contact } from "../components/organism/contact"
import { Constants } from "../constants"
import { ServicesFeedbackBlock } from "../components/organism/services-feedback-block"
import { ContactsBlock } from "../components/organism/contacts-block"


const IndexPage = ({ location }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  const { servicesFeedbacks, perfTeamCallToActionBlock, perfTeamHeader } = Constants
  return (
    <Layout
      background="spaceBackgroundImage"
      callToActionButton
      {...site.siteMetadata}
      {...perfTeamHeader}
    >
      <Seo title="Astraload" location={location} />

      <main>
        <OurDifferents />
        <Advantages />
        <OurToolsSection />

        <ServicesFeedbackBlock
          title={"Join a good company of our clients"}
          feedbacks={servicesFeedbacks.feedbacks}
        />

        <ContactsBlock
          callToActionBlockInfo={perfTeamCallToActionBlock}
        />
      </main>
    </Layout>
  )
}
export default IndexPage

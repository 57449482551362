import React, { useRef } from "react"
import { withStyles } from "@material-ui/core"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { Constants } from "../../constants"
import { useInView } from "react-intersection-observer"
import useScrollPosition from "../../hooks/useScrollPosition"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import { LinkWithArrow } from "../moleculs/link-with-arrow"
import { ContentContainer } from "../moleculs/content-container"
import Grid from "@material-ui/core/Grid"
import { useFluidUrl } from "../images"

function Advantages(props) {
  const { classes } = props

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  const img = useFluidUrl('advantagesImg')

  function getAnimationClasses(defaultClass, animationClass) {
    if (inView) return `${defaultClass} ${animationClass}`
    return defaultClass
  }

  const linkAnimationClass = getAnimationClasses(classes.button, classes.buttonAnimation)
  const imgAnimationClass = getAnimationClasses(classes.dashboardImage, classes.dashboardImageAnimation)

  return (
    <ContentContainer ref={sectionRef} customClass={classes.container}>
      <Grid
        container
        spacing={6}
        justifyContent={"space-between"}
      >
        <Grid
          item
          lg={4}
          md={12}
        >
          <TitleWithDescriptionBlock
            {...Constants.advantages}
            titleClassName={classes.titleClassName}
            descriptionClassName={classes.descriptionClassName}
            shouldAnimate={shouldAnimate}
            left
          />

          <div className={linkAnimationClass}>
            <LinkWithArrow
              text={'Learn more'}
              link={'/astraload-saas/'}
            />
          </div>
        </Grid>
        <Grid
          item
          lg={7}
          md={12}
        >
          <div ref={ref} >
            <div className={imgAnimationClass}>
              <img src={img.src} alt="" />
            </div>
          </div>
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  "@keyframes imgFadeInOut": {
    from: { opacity: 0, bottom: "40px" },
    to: { opacity: 1, bottom: "0px" },
  },
  titleClassName: {
    color: theme.palette.text.secondary,
  },
  descriptionClassName: {
    color: theme.palette.text.primary,
  },
  button: {
    opacity: 0,
    position: "relative",
    marginTop: "45px",
    marginLeft: "1px",
    zIndex: 2000,
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      ...theme.animations.noAnimation,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "25px",
      marginBottom: "0px",
    },
  },
  buttonAnimation: {
    ...theme.animations.itemFadeInUp,
    animationDelay: ".35s",

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  dashboardImageAnimation: {
    position: "relative",
    opacity: 0,
    animation: "$imgFadeInOut .7s ease-in-out forwards",
    animationDelay: ".35s",

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  customButtonText: {
    padding: "0 40px",
  },
  visible: {
    opacity: 1,
  },
  container: {
    position: "relative"
  },
  dashboardImage: {
    position: "absolute",
    right: 40,
    bottom: 0,
    maxWidth: 670,
    width: "100%",
    opacity: 0,

    [theme.breakpoints.down("md")]: {
      right: 0,
      position: "relative",
      bottom: -100,
      marginTop: -60
    },

    [theme.breakpoints.down("sm")]: {
      bottom: -50,
      marginTop: -20
    },

    "& img": {
      width: "100%",
      display: "block"
    },
  }
})

// eslint-disable-next-line
Advantages = withStyles(styles)(Advantages)

export { Advantages }

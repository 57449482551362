import React from "react"
import { GatsbyImageBackground } from "../images"
import { FooterCallToActionBlock } from "../moleculs/footer-call-to-action-block"
import { Element } from "react-scroll"
import { withStyles } from "@material-ui/core"

function Contact({ classes, callToActionBlockInfo, isHome = false }) {
  const footerClasses = isHome
    ? `${classes.wrapper} ${classes.footerDark}`
    : classes.wrapper

  const containerClasses = isHome
    ? `${classes.container} ${classes.paddingContainer}`
    : classes.container

  return (
    <div className={footerClasses}>
      <GatsbyImageBackground
        className={containerClasses}
        name="spaceBackgroundImage"
      >
        <Element name="footerCallToActionBlock">
          <FooterCallToActionBlock
            callToActionBlockInfo={callToActionBlockInfo}
          />
        </Element>
      </GatsbyImageBackground>
    </div>
  )
}

const styles = theme => {
  return {
    wrapper: {
      position: "relative",
      backgroundColor: theme.palette.text.white,
    },
    footerDark: {
      background: `linear-gradient(0deg, ${theme.palette.text.white} 60%, ${theme.palette.background.effort} 60%)`,
    },
    container: {
      display: "flex",
      height: "1475px",
      flexDirection: "column",
      justifyContent: "center",
      clipPath: "polygon(0 23.3%, 100% 0, 100% 82%, 0% 100%)",
      "-webkit-clip-path": "polygon(0 23.3%, 100% 0, 100% 82%, 0% 100%)",
      opacity: "1 !important",
      [theme.breakpoints.down("sm")]: {
        height: "1155px",
        top: "-47px",
        paddingTop: "10px",
        paddingBottom: "0px",
        clipPath: "polygon(0 15.6%, 100% 0, 100% 82%, 0% 100%)",
        "-webkit-clip-path": "polygon(0 15.6%, 100% 0, 100% 82%, 0% 100%)",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0",
        height: "1163px",
        clipPath: ({ isHome }) =>
          isHome
            ? "polygon(0 5%, 100% 0, 100% 100%, 0% 100%)"
            : "polygon(0 6.5%, 100% 0, 100% 100%, 0% 100%)",
        "-webkit-clip-path": ({ isHome }) =>
          isHome
            ? "polygon(0 5%, 100% 0, 100% 92%, 0% 100%)"
            : "polygon(0 6.5%, 100% 0, 100% 92%, 0% 100%)",
      },
    },
    paddingContainer: {
      paddingTop: "117px",
      height: "1362px",
      [theme.breakpoints.down("sm")]: {
        height: "1295px",
        top: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        clipPath: "polygon(0 14%, 100% 0, 100% 82%, 0% 100%)",
        "-webkit-clip-path": "polygon(0 14%, 100% 0, 100% 82%, 0% 100%)",
      },
      [theme.breakpoints.down("xs")]: {
        height: "1060px",
        paddingTop: "84px",
        justifyContent: "flex-start",
      },
    },
  }
}

// eslint-disable-next-line
Contact = withStyles(styles)(Contact)

export { Contact }

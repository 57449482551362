import React from "react"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { useFluidUrl } from "../images"

function ServicesFeedbackItem(props) {
  const { classes, logoName, name, company, feedback } = props
  const imgSrc = useFluidUrl(logoName)

  return (
    <div className={classes.feedback}>
      <div className={classes.logo}>
        <img src={imgSrc.src} alt=""/>
      </div>
      <div>
        <Typography
          variant="h4"
          className={classes.name}
          children={name}
        />

        <Typography
          variant="subtitle1"
          className={classes.company}
          children={company}
        />

        <Typography
          variant="body2"
          className={classes.text}
          children={feedback}
        />
      </div>
    </div>
  )
}

const styles = theme => ({
  name: {},
  company: {
    marginBottom: 20
  },
  text: {
    fontWeight: 300,
  },
  logo: {
    maxWidth: 80,
    marginBottom: 30,

    "& img": {
      width: "100%",
      display: "block"
    }
  }
})

// eslint-disable-next-line
ServicesFeedbackItem = withStyles(styles)(ServicesFeedbackItem)

export { ServicesFeedbackItem }

import React, { useRef } from "react"
import { Constants } from "../../constants"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { ContentContainer } from "./content-container"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"


function OurDifferents({ classes }) {
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)
  const { title, description } = Constants.howWeDifferent

  return (
    <ContentContainer ref={sectionRef}>
      <div className={classes.container}>
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            lg={4}
            md={12}
          >
            <Typography variant="h2" className={`${classes.title}`}>
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
          >
            <Typography
              variant="body1"
              className={`${classes.description}`}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </ContentContainer>
  )
}

const styles = theme => ({
  title: {
    background: "linear-gradient(180deg, #BF31D7 0%, #4D18DD 100%)",
    textFillColor: "transparent",
    "-webkit-background-clip": "text",

    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "40px"
    },
  },
  description: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    letterSpacing: "-0.5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px"
    },
  }
})

// eslint-disable-next-line
OurDifferents = withStyles(styles)(OurDifferents)

export { OurDifferents }

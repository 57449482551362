import React from "react"
import { withStyles } from "@material-ui/core"
import { LinkArrow } from "../svg/link-arrow"
import { Link } from "gatsby"

function LinkWithArrow(props) {
  const { classes, text, link } = props

  return (
    <div>
      <Link to={link} target="_blank" className={classes.link}>
        {text}
        <LinkArrow className={classes.linkArrow}/>
      </Link>
    </div>
  )

}

const styles = theme => {
  return {
    link: {
      color: "#FFA600",
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "50px",
      position: "relative",
      paddingRight: 25,
      display: "inline-block",

      "&:hover $linkArrow": {
        transform: "translate(10px, -46%)",
      }
    },
    linkArrow: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(0, -46%)",
      transition: "all .3s ease-out",

      "& svg": {
        display: "block"
      }
    },
  }
}

// eslint-disable-next-line
LinkWithArrow = withStyles(styles)(LinkWithArrow)

export { LinkWithArrow }
